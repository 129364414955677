// VITE deals with this
import '../scss/app.scss';
import 'virtual:fonts.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// Set some helpful variables
import {
    contactPageTrigger,
    enableBackButtonUI,
    enableHamburgerMenu,
    enableTabSwitching, enableULeistungen,
    initializeSliders,
    setCSSVars,
    teamDetailPage
} from './utils';

// import gsap from "gsap";
setCSSVars();
initializeSliders();
enableTabSwitching();
enableBackButtonUI();
enableHamburgerMenu();
teamDetailPage();
contactPageTrigger();
enableULeistungen();



document.addEventListener("DOMContentLoaded",function(){
    AOS.init({
        offset: 100,
        duration: 350,
        easing: 'ease-in-out-sine',
        delay: 0,
    });
});
