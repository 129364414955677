import Glide from "@glidejs/glide";

export const setCSSVars = () => {
    document.querySelector(':root').style.setProperty('--headerHeight', '65px');
    document.querySelector(':root').style.setProperty('--vh', window.innerHeight + 'px');
};

export const initializeSliders = () => {
    if (document.querySelector('.glide')) {
        let totalSlides;
        const CustomLength = function (Glide, Components, Events) {
            return {
                mount() {
                    Events.emit('slider.length', Components.Sizes.length)
                }
            }
        }
        console.log(document.querySelectorAll('.glide'));

        document.querySelectorAll('.glide').forEach(el => {
            const globalSlider = new Glide(el, {
                type: 'slider',
                perView: 4,
                gap: 20,
                autoplay: false,
                hoverpause: false,
                breakpoints: {
                    500: {
                        perView: 1
                    },
                    768: {
                        perView: 2
                    },
                    1024: {
                        perView: 3
                    }
                }
            });

            globalSlider.on('run.before', (obj) => {
                /*let perView = globalSlider.settings.perView;
                let currentIndex = globalSlider.index;
                let calc = totalSlides - perView;

                console.log(obj);

                if (currentIndex === calc && obj.direction === '>') {
                    window.globalSlider.index = -1;
                } else {
                    window.globalSlider.index = 0;
                }*/

            });

            globalSlider.on('slider.length', (length) => {
                totalSlides = length;
            });

            setTimeout(() => {
                globalSlider.mount({CustomLength});
                console.log('run this');
            }, 500);
        });
    }

    if (document.querySelector('.fullglide')) {
        window.mainSlider = new Glide(document.querySelector('.fullglide'), {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            gap: 0,
            autoplay: 5500,
            animationDuration: 1500,
            hoverpause: false,
        });

        setTimeout(() => {
            window.mainSlider.mount();
        }, 500);
    }

    if (document.querySelector('.service-glide')) {
        const serviceMobileSlider = new Glide(document.querySelector('.service-glide'), {
            type: 'carousel',
            startAt: 0,
            perView: 1,
            gap: 22,
            autoplay: false,
            hoverpause: false,
        }).mount();
    }
};

export const enableTabSwitching = () => {
    if (document.querySelectorAll('.change-nav-content ul > li')) {
        const tabs = document.querySelectorAll('.change-nav-content ul > li');
        const resultContainer = document.querySelector('.change-nav-content #swap-content');

        tabs.forEach((tab, index) => {
            tab.addEventListener('click', () => {
                tabs.forEach(tab => tab.classList.remove('active'));
                resultContainer.classList.remove('active');
                tab.classList.add('active');
                setTimeout(() => {
                    resultContainer.classList.add('active');
                });
                resultContainer.innerHTML = tab.getAttribute('data-content');
            });
        });
    }
};

export const enableBackButtonUI = () => {
    if (document.querySelector('.back-button')) {
        document.querySelector('.back-button').addEventListener('click', () => {
            window.history.back();
        });
    }
};

export const enableHamburgerMenu = () => {
    if (document.querySelector('.hamby')) {
        document.querySelector('.hamby').addEventListener('click', () => {
            document.querySelector('.hamby').classList.toggle('active');
            document.querySelector('.top-nav').classList.toggle('active');
        });
    }
};

export const teamDetailPage = () => {
    if (document.querySelector('.team-card')) {
        document.querySelectorAll('.team-card').forEach(card => {
            card.addEventListener('click', () => {
                console.log(card);
                document.querySelector('.team-detail-container').classList.add('active');
                document.body.classList.add('no-scroll');
                document.querySelector('.team-detail-container .content-wrap .team-name-pos h2').innerHTML = card.getAttribute('data-team-name');
                document.querySelector('.team-detail-container .content-wrap .team-name-pos p').innerHTML = card.getAttribute('data-team-pos');
                document.querySelector('.team-detail-container .contact-strip a.tel span').textContent = card.getAttribute('data-team-phone');
                document.querySelector('.team-detail-container .contact-strip a.tel').setAttribute('href', 'tel:' + card.getAttribute('data-team-phone'));
                document.querySelector('.team-detail-container .contact-strip a.email span').textContent = card.getAttribute('data-team-email').replace('mailto:', '');
                document.querySelector('.team-detail-container .contact-strip a.email').setAttribute('href', card.getAttribute('data-team-email'));
                document.querySelector('.team-detail-container .content-wrap .team-content > div:nth-child(2)').innerHTML = card.getAttribute('data-team-content');
                document.querySelector('.team-detail-container .team-image img').src = card.getAttribute('data-team-image');
            });
        });

        document.querySelector('.team-detail-container .team-image .close-btn').addEventListener('click', () => {
            onClickCloseButton();
        });

        document.querySelector('.team-detail-container .content-wrap .close-btn').addEventListener('click', () => {
            onClickCloseButton();
        });

        document.querySelector('.team-detail-container a.contact-hash-form').addEventListener('click', (e) => {
            e.preventDefault();
            document.querySelector('.team-detail-container .team-content').classList.add('move-up');
            document.querySelector('.team-detail-container .team-contact-form').style.display = 'block';
            document.querySelector('.team-detail-container .contact-strip').style.display = 'none';
        });

        function onClickCloseButton() {
            document.body.classList.remove('no-scroll');
            document.querySelector('.team-detail-container').classList.remove('active');
            document.querySelector('.team-detail-container .team-image img').src = '';
            document.querySelector('.team-detail-container .content-wrap .team-content > div:nth-child(2)').innerHTML = '';
            document.querySelector('.team-detail-container .content-wrap .team-name-pos h2').innerHTML = '';
            document.querySelector('.team-detail-container .content-wrap .team-name-pos p').innerHTML = '';
            document.querySelector('.team-detail-container .team-content').classList.remove('move-up');
            document.querySelector('.team-detail-container .team-contact-form').style.display = 'none';
            document.querySelector('.team-detail-container .contact-strip').style.display = 'flex';
            document.querySelector('.team-detail-container .contact-strip a.tel span').textContent = '';
            document.querySelector('.team-detail-container .contact-strip a.email span').textContent = '';
            document.querySelector('.team-detail-container .contact-strip a.email').setAttribute('href', card.getAttribute('data-team-email'));
        }
    }
};

export const contactPageTrigger = () => {
    if (document.querySelector('a.contact-hash-form')) {
        document.querySelector('a.contact-hash-form').addEventListener('click', (e) => {
            e.preventDefault();
            document.querySelector('.contact-content').classList.add('move-up');
            document.querySelector('.team-contact-form').style.display = 'block';
            document.querySelector('.contact-strip').style.display = 'none';
        });
    }
};
export const enableULeistungen = () => {
    if (document.querySelector('a.u-leistungen-cta')) {
        document.querySelector('a.u-leistungen-cta').addEventListener('click', (e) => {
            e.preventDefault();
            document.querySelector('.company-detail-container').classList.add('active');
            document.body.classList.add('no-scroll');
        });

        document.querySelector('.company-detail-container .team-image .close-btn').addEventListener('click', () => {
            closeULeistungen()
        });

        document.querySelector('.company-detail-container .close-btn').addEventListener('click', () => {
            closeULeistungen()
        });

        document.querySelector('.company-detail-container a.contact-hash-form').addEventListener('click', (e) => {
            e.preventDefault();
            document.querySelector('.company-detail-container .team-content').classList.add('move-up');
            document.querySelector('.company-detail-container .team-contact-form').style.display = 'block';
        });

        function closeULeistungen() {
            document.body.classList.remove('no-scroll');
            document.querySelector('.company-detail-container').classList.remove('active');
            document.querySelector('.company-detail-container .team-content').classList.remove('move-up');
            document.querySelector('.company-detail-container .team-contact-form').style.display = 'none';
        }
    }
};

export default {
    setCSSVars,
    initializeSliders,
    enableTabSwitching,
    enableBackButtonUI,
    enableHamburgerMenu,
    teamDetailPage,
    contactPageTrigger,
    enableULeistungen,
};
